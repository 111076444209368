<template>
    <v-container>
        <AdBTWR v-if="!user.subscribed"/>

        <h1 class="page-title font-weight-light">Branding Guidelines</h1>

        <p>Please use the following guidelines whenever representing the myGMRS logos. Our logos or other branding may only be used with written permission except in the case of providing links to this website.</p>

        <h2 class="font-weight-light">Brand Colors</h2>

        <div class="d-flex flex-wrap justify-space-around">
            <div class="swatch d-flex justify-center align-center text-center elevation-3 my-3 rfnavy white--text">Navy Blue<br>#012754</div>
            <div class="swatch d-flex justify-center align-center text-center elevation-3 my-3 rforange white--text">Orange<br>#F47434</div>
            <div class="swatch d-flex justify-center align-center text-center elevation-3 my-3 rfaccent white--text">Sky Blue<br>#049BEB</div>
            <div class="swatch d-flex justify-center align-center text-center elevation-3 my-3 white black--text">White<br>#FFFFFF</div>
            <div class="swatch d-flex justify-center align-center text-center elevation-3 my-3 almostblack white--text">Almost Black<br>#282828</div>
        </div>

        <h2 class="font-weight-light">Logos</h2>

        <p><strong>Typeface</strong>: <a href="https://www.myfonts.com/fonts/urw/eurostile/t-black-extended/" target="_blank">Eurostile Black Extended</a><v-icon small class="ml-1">mdi-open-in-new</v-icon></p>

        <h3 class="font-weight-light">Stacked Logo (Preferred)</h3>

        <v-img></v-img>

        <h3 class="font-weight-light">Horizontal Logo</h3>

        <h3 class="font-weight-light">Icon Logo</h3>

        <h3 class="font-weight-light">Black and White Logo</h3>

        <h3 class="font-weight-light">Network Logo</h3>
    </v-container>
</template>

<style>
    .swatch{
        height: 150px;
        width: 150px;
    }
</style>

<script>
    import AdBTWR from '@/components/ads/BTWR';

    export default {
        props: ['user'],

        components: {AdBTWR},
    }
</script>
